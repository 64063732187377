<template>
    <div class="shop-list">
        <div class="shop-list-head">
            <div class="shop-list-filter">
                <div @click="back">
                    <span v-if="device">
                        返回
                    </span>
                    <Svgs v-else color="#000" name="iconfanhui"></Svgs>
                </div>
                <div @click="goAddress">
                    <span class="text">{{this.address.name || '请选择用餐地址'}}</span>
                    <Svgs class="down" color="#E1E1E1" name="iconfontdown"></Svgs>
                </div>
            </div>
            <div class="shop-list-search">
                <searchRow placeholder="请输入餐厅名称" v-model="keyword"></searchRow>
            </div>
        </div>
        <ScrollBar class="list-content" :class="{'list-content1':showBottom}" @onTop="handleTop" :style="listStyle" @onBottom="scrollBottom" :loadType="loadType">
            <div slot="scrollBar">
                <template v-if="shopList.length">
                    <div
                        v-for="(item, index) in shopList"
                        :key="index"
                        @click="shopDetail(item)"
                        class="shop-list-row"
                    >
                        <div class="item-img">
                            <img :src="item.imgThumbnail || '@/assets/img/default.png'" class="shop-img" @error="setErrorImg" />
                            <Svgs color="#fff" :name="getType(item.payProfile).icon"></Svgs>
                        </div>
                        <div class="item-info">
                            <div class="fullName">{{item.fullName}}</div>
                            <div class="start">
                                <Svgs color="#FFCD30" name="iconxingxing"></Svgs>
                                {{(item.starVal).toFixed(1)}}
                            </div>
                            <div class="distance">
                                <p>￥{{item.priceAvgVal}}/人</p>
                                <p>距离：{{countDistance(item.distance)}}</p>
                            </div>

                            <p>
                                <span>{{item.regionVal}}</span>
                                <span class="vertical" v-if="item.categoryVal">|</span>
                                <span>{{item.categoryVal}}</span>
                            </p>
                        </div>
                    </div>
                </template>
                <!-- <NoData v-if="!shopList.length"></NoData> -->
            </div>
        </ScrollBar>
        <HospitalPop @onOk="chooseHosRes" ref="hospitalPop" />
        <SearchCity :cityList="cityList" @onOk="cityOk" ref="eventCity"></SearchCity>
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters, mapMutations } from "vuex";
import { indicator } from 'smart-indicator'
import {
    SmartStorage
} from 'smart-core-util'
export default {
    data() {
        return {
            keyword: "",
            shopList: [],
            filterOpt: [],
            // sortShow: false,
            orgFilterOpt: [],
            filters: "",
            temp: [],
            loadType: "",
            pageindex: 1,
            finish: false,
            listStyle: {},
            cityList: [],
            showBottom: false,
            scrollBottomF: true,
            firstShow: true
        };
    },
    mounted() {
        this["FRESH_SHOP"](false);
        this.getShopList(true)
    },
    watch: {
        refreshShop: function(val, oldVal) {
            if (val) {
                this.filterOpt = _.cloneDeep(this.orgFilterOpt);
                this.filters = "";
                this.keyword = ''
                this.getShopList(true);
                this["FRESH_SHOP"](false);
            }
        },
        'keyword': function (keyword) {
            this.firstShow = false
            if(keyword != ''){
                this.getShopList(true);
            }else{
                this.shopList = []
            }
        }
    },
    computed: {
        ...mapGetters(["event", "address", "refreshShop", "orderDetail"]),
        device(){
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf("micromessenger") != -1;
            if (isWeixin) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        ...mapMutations(["FRESH_SHOP", "ADDRESS", "HOSPITAL_GPS", "GAODE_SHOP_DETAIL"]),
        /** 图片加载失败时调用方法 */
        setErrorImg(e) {
            var img = e.srcElement;
            img.src = require("@/assets/img/default.png");
            img.οnerrοr = null;
        },
        urlConversion(path) {
            let imgpath = path
            if(!imgpath){
                return ''
            }
            if(path[4] != 's' && imgpath){
                imgpath = 'https:' + imgpath.substring(5, imgpath.length);
            }
            return imgpath
        },
        getStyle() {
            let height = document.querySelector(".list-content").clientHeight
            this.listStyle = {
                height: height + 'px'
            }
        },
        getShopList(fresh) {
            if (fresh) {
                this.index = 1;
                this.finish = false;
                this.pageindex = 1;
                if (document.querySelector(".list-content")) {
                    document.querySelector(".list-content").scrollTop = 0;
                }
            }
            if(['pfizer'].includes(SmartStorage.get('tenant_code')) && this.firstShow){
                return
            }
            let params = {
                pageindex: this.pageindex,
                pageSize: 10,
                CityName: this.address.city,
                NL: this.address.latitude,
                EL: this.address.longitude,
                filters: this.filters,
                Keyword: this.firstShow ? '餐厅' : this.keyword
            };
            //  let params = {
            //     page: this.pageindex,
            //     offset: 10,
            //     city: this.address.city,
            //     NL: this.address.latitude,
            //     EL: this.address.longitude,
            //     filters: this.filters,
            //     Keywords: this.firstShow ? '餐厅' : this.keyword,
            //     radius: 50000
            // };
            var p = new RegExp(/\s+/g);
            if (p.test(this.keyword)) {
                this.toast("请填写正确的餐厅名称！");
                return
            }
            if(this.keyword.length == 0 && !this.firstShow){
                this.toast("请填写餐厅名称！");
                return
            }
            if(this.event.tenant_name == '会引擎-huarun'){
                let filter = ['酒吧','俱乐部','夜总会','KTV','ktv','Ktv','KTv','kTv','kTV',"ktV",'kTV','KtV']
                for(let item of filter){
                    if(this.keyword.indexOf(item) > -1){
                        this.toast("你查找的餐厅不符合合规要求，请从新输入。");
                        return
                    }
                }
            }
            miceService.getGaoDeShopList(params).then(res => {
                if(res && res.success){
                    for(let item of res.data){
                        item.imgThumbnail = this.urlConversion(item.imgThumbnail)
                    }
                    this.shopList = fresh ? res.data : this.shopList.concat(res.data);
                    this.finish = res.data.length < 10 ? true : false;
                    this.scrollBottomF = true
                    setTimeout(() => {
                        this.loadType = "";
                    }, 1000);
                }
            });
        },
        countDistance(distance) {
            let dis = distance.toFixed(0);
            if (dis.length > 3) {
                return `${(dis / 1000).toFixed(1)}km`;
            } else {
                return dis + "m";
            }
        },
        scrollBottom() {
            if (this.finish) {
                return;
            }
            if(!this.scrollBottomF){
                return;
            }
            this.scrollBottomF = false
            this.loadType = "bottom";
            this.pageindex += 1;
            this.getShopList();
        },
        handleTop() {
            this.loadType = "top";
            var p = new RegExp(/\s+/g);
            if (p.test(this.keyword)) {
                this.toast("请填写正确的餐厅名称！");
                setTimeout(() => {
                    this.loadType = "";
                }, 1000);
                return
            }
            if(this.keyword.length == 0 && !this.firstShow){
                this.toast("请填写餐厅名称！");
                setTimeout(() => {
                    this.loadType = "";
                }, 1000);
                return
            }
            this.getShopList(true);
        },
        setSort(btn, item, index, index1) {
            /* global _ */
            let btno = _.cloneDeep(btn);
            let itemo = _.cloneDeep(item);
            if (item.multiple) {
                btno.isSelected = !btn.isSelected;
                itemo.options[index1] = btno;
                this.$set(this.filterOpt, index, itemo);
            } else {
                itemo.options.map(i => {
                    i.isSelected = false;
                });
                btno.isSelected = !btn.isSelected;
                itemo.options[index1] = btno;
                this.$set(this.filterOpt, index, itemo);
            }
        },
        reset() {
            this.filterOpt = _.cloneDeep(this.orgFilterOpt);
            this.filters = "";
            this.getShopList(true);
            this.sortShow = false;
        },
        submit() {
            let arr = [];
            this.filterOpt.forEach((item, index) => {
                if (item.multiple) {
                    let multipleItems = [];
                    item.options.forEach(option => {
                        if (option.isSelected) {
                            const itemStr = `${item.val} ${option.sql}`;
                            multipleItems.push(itemStr);
                        }
                    });
                    let multipleStr = multipleItems.join(" OR ");
                    if (multipleStr) {
                        arr.push(`(${multipleStr})`);
                    }
                } else {
                    let itemStr = "";
                    item.options.forEach(option => {
                        if (option.isSelected) {
                            itemStr = `(${item.val} ${option.sql})`;
                        }
                    });
                    if (itemStr) {
                        arr.push(itemStr);
                    }
                }
            });
            this.filters = arr.join(" AND ");
            this.getShopList(true);
            this.sortShow = false;
        },
        getType(payProfile) {
            if (!payProfile) return false;
            let tmpPayMode = JSON.parse(payProfile).PayModes; // 可能返回数字或数组
            let modes =
                typeof tmpPayMode == "number" ? tmpPayMode : tmpPayMode[0];

            let result = {};
            switch (modes) {
                case 2:
                    result = {
                        icon: "iconzhifubao",
                        color: "#00aaee"
                    };
                    break;
                case 3:
                    result = {
                        icon: "iconweixin1",
                        color: "#FF821D"
                    };
                    break;
                case 4:
                    result = {
                        icon: "iconmeican",
                        color: "#11d350"
                    };
                    break;
                case 5:
                    result = {
                        icon: "icondingcanxiaomishu2",
                        color: ""
                    };
                    break;
                case 6:
                    result = {
                        icon: "iconkoubei",
                        color: "#e8470b"
                    };
                    break;
                case 8:
                case 15:
                    result = {
                        icon: "iconelemo",
                        color: "#1e89e0"
                    };
                    break;
                case 11:
                    result = {
                        icon: "icona-gaodeloukong",
                        color: "#00aaee"
                    };
                    break;
            }
            return result;
        },
        
        back() {
            if(['pfizer'].includes(SmartStorage.get('tenant_code'))){
                this.$router.push('/recommendShop')
                return
            }
            this.$router.back()
        },
        goAddress() {
            if(['pfizer'].includes(SmartStorage.get('tenant_code'))){
                let params = {
                    type: 2
                }
                miceService.queryRegion(params).then(res => {
                    this.cityList = res.content || res;
                    this.$refs.eventCity.openPop()
                })
                return
            }
            this.$router.push("/selectAddress");
        },
        cityOk(item) {
            let address = {
                longitude: item.longitude,
                latitude: item.latitude,
                name: item.txt,
                city: item.txt
            }
            this["ADDRESS"](address)
            if(['pfizer'].includes(SmartStorage.get('tenant_code'))){
                SmartStorage.set('regionId',item.regionId)
                this.$refs.hospitalPop.openPop(item.regionId)
                return
            }
            this.getShopList(true);
        },
        chooseHosRes(item) {
            SmartStorage.set('hospitalId',item.rowId)
            this.getShopList(true);
        },
        shopDetail(item) {
            this["GAODE_SHOP_DETAIL"](item)
            this.$router.push(
                `/shopDetail?from=gaoDeShopList`
            );
        },
    },
    // 进入商家详情后返回  列表位置不变动
    beforeRouteLeave(to, from, next) {
        if (document.querySelector(".list-content")) {
            this.scrollTop = document.querySelector(".list-content").scrollTop;
        }
        next();
    },
    beforeRouteEnter(to, from, next) {
        if (to.query.refresh) {
            next();
        } else {
            next(vm => {
                if (document.querySelector(".list-content")) {
                    document.querySelector(".list-content").scrollTop =
                        vm.scrollTop;
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>

.shop-list {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    * {
        text-align: left;
    }
    .shop-list-head {
        box-sizing: border-box;
        width: 100%;
        height: 1rem;
        background-color: #fff;

        .shop-list-filter {
            position: absolute;
            background-color: #fff;
            height: 0.4rem;
            box-sizing: border-box;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            padding: 0 0.25rem 0 0.25rem;
            z-index: 4;
            width: 100%;

            div:nth-child(2) {
                padding-left: 0.2rem;
                margin-right: 0.2rem;
                width: 68%;
                font-size: 0.14rem;
                height: 0.32rem;
                line-height: 0.32rem;
                position: relative;
                box-sizing: border-box;
                text-align: left;

                .text {
                    max-width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    margin-right: 5px;
                }
                .location,
                .down {
                    position: absolute;
                    top: 2px;
                }
                .location {
                    left: 10px;
                }
                .down {
                    top: 5px;
                }
            }

            .shop-list-head-icon {
                position: absolute;
                right: 0.25rem;
                top: 0.08rem;
            }
        }

        .shop-list-search {
            position: absolute;
            text-align: center;
            box-sizing: border-box;
            padding: 0 0.25rem;
            top: 0.55rem;
            width: 100%;
        }

        .xian-xia {
            margin: 0 0.25rem;
            position: absolute;
            box-sizing: border-box;
            text-align: center;
            top: 1rem;
            width: calc(100% - 0.5rem);
            border-bottom: 1px solid #F5F5F5;
            height: 0.28rem;
            line-height: 0.28rem;
            color: #909399;

            span {
                color:  var(--themeColor);
            }
        }

        .sort {
            padding: 0.1rem 0.25rem 0 0.25rem;
            position: absolute;
            right: 0;
            left: 0;
            z-index: 2;
            background: #fff;
            top: 0.4rem;
            border-bottom-left-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;

            p {
                height: 0.3rem;
                line-height: 0.3rem;
                font-size: 0.16rem;
            }

            .foot-btn {
                margin: 0.2rem 0;
                display: flex;
                justify-content: space-between;

                .btn-default {
                    width: 1.5rem;
                    text-align: center;
                    height: 0.5rem;
                    line-height: 0.5rem;
                    border-radius: 0.25rem;
                    background: #f5f5f5;
                    color: #13161b;
                }
            }
            .active {
                background: var(--themeColor)!important;
                color: #fff !important;
            }
        }

        .filter-btn {
            height: 0.36rem;
            line-height: 0.36rem;
            width: 1rem;
            text-align: center;
            border-radius: 0.18rem;
            display: inline-block;
            margin: 0.03rem 0.08rem 0.06rem 0;
            background: #f5f5f5;
            color: #13161b;
        }
    }
    .list-content {
        position: absolute;
        height: calc(100% - 1.2rem)!important;
        top: 1rem;
        width: 100%;
        bottom: 0;
        overflow: scroll;
    }
    .list-content1 {
        top: 1.3rem;
    }
    .shop-list-row {
        width: 100%;
        display: flex;
        padding: 0 0.25rem;
        box-sizing: border-box;
        margin: 0.12rem 0;
        .item-img {
            width: 1.08rem;
            height: 1.08rem;
            border-radius: 0.1rem;
            margin-right: 0.22rem;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.1rem;
            }
            .icon {
                position: absolute;
                bottom: 0.1rem;
                right: 0.1rem;
                width: 0.24rem;
                height: 0.24rem;
            }
        }
        .item-info {
            width: calc(100% - 1.3rem);
            box-sizing: border-box;
            .fullName {
                color: #13161b;
                font-size: 0.16rem;
                margin: 0.05rem 0 0.1rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: 600;
            }
            .start {
                .icon {
                    width: 0.12rem;
                    height: 0.12rem;
                }
                margin-bottom: 0.05rem;
                color: #90939a;
            }
            .distance {
                display: flex;
                justify-content: space-between;
            }
            p {
                color: #90939a;
                font-size: 0.12rem;
                margin-bottom: 0.05rem;
            }
        }
    }
    .cover {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        opacity: 0.3;
        background: #333;
        z-index: 1;
    }
}
</style>